.button-container {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.button-container > * {
  margin-block: 1em;
  outline: 1px solid #e7e7e7;
}
